















import { defineComponent, reactive } from "@vue/composition-api"
import IconButton from "@/components/buttons/IconButton.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { Meeting } from "@/models/Meeting"
import { memoContainer } from "@/containers/MemoContainer"

interface State {
  isLoading: boolean
}

export default defineComponent({
  props: {
    meeting: {
      type: Meeting,
      required: true,
    },
  },
  components: {
    IconButton,
    I18nFormattedMessage,
  },
  setup(props) {
    const state = reactive<State>({
      isLoading: false,
    })
    const { formatMessage } = i18nContainer.useContainer()
    const { sendMessage, getMembers } = teamsContextContainer.useContainer()
    const { showSuccessMessage } = alertContainer.useContainer()
    const { styleMemo } = memoContainer.useContainer()
    const { state: meetingState } = meetingContainer.useContainer()

    const createMessageHtml = (meeting: Meeting) => {
      const memo = styleMemo(meetingState.memo.value || "")

      let ret = `<div>`
      ret += `<h1>${
        meeting.subject
      }(${meeting.startTime.toDateJpStringFull()}) <議事メモ投稿></h1>`
      ret += `${memo}`
      ret += `<br>`
      ret += `<h2>開催場所</h2>`
      ret += `${meeting.location ||
        '<label style="color:#AAA">(設定なし)</label>'}`
      ret += `<br>`
      ret += `<br>`
      ret += `<h2>参加者</h2>`
      ret += `${meeting.users.map(u => u.displayName).join(", ")}`
      ret += `</div>`
      return ret
    }

    const onPostToChannelButtonClick = async () => {
      state.isLoading = true
      const members = await getMembers()
      showSuccessMessage("送信をリクエストしました")
      await sendMessage(
        createMessageHtml(props.meeting),
        members.filter(u =>
          props.meeting.users.find(mu => mu.email === u.email)
        )
      )
      showSuccessMessage("送信完了が完了しました")
      state.isLoading = false
    }

    return {
      state,
      onPostToChannelButtonClick,
      i18nFormattedMessage: formatMessage,
    }
  },
})
